<template>
  <div class="teams_detail_wrapper">
    <Navigation />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="teams_detail container">
      <div class="teams_detail__container">
        <TeamDetailPreloader v-if="state.preloadMain" />
        <div class="teams_detail__content" v-else>
          <TeamDetailContent
            :isOwner="state.teamInfo.owner_id == state.account_id"
            :source="state.teamInfo"
            :categories="state.categories"
            :openEditPopup="openEditPopup"
            :onEditProfileImage="() => state.showPopupCroppie = true"
            :gotoUserInfo="gotoUserInfo"
            :removeTeamMember="removeTeamMember"
            :gotoUserAchievement="gotoUserAchievement"
            :onInviteMember="() => state.showInviteMember = true"
            :onChangeLeader="() => state.showChangeLeader = true"
            :onCancelChangeLeader="() => state.showCancelChangeLeader = true"
            :onCreateSlackChannel="() => state.showCreateSlackChannel = true"
          />
          <!-- TODO: 共通化前に作成されたPRを共通化後にマージしたため、削除機能が TeamDetailContent に含まれていない -->
          <div class="teams_detail__content__form__delete" v-if="state.account_id == state.teamInfo.owner_id && !state.teamInfo.assigned">
            <button
                v-if="!isDisabledTeams"
                @click="state.confirmDelete = true"
                type="button"
                :disabled="false"
                class="btn-md btn-red shadow"
            >チームを解散・削除する
            </button>
          </div>
        </div>
        <TeamInfoEdit
          v-if="state.showPopupEdit"
          :info="state.teamInfo"
          :field="state.editField"
          @updateValue="updateValue"
          @close="state.showPopupEdit = false"
        />
        <InviteMember
          v-if="state.showInviteMember"
          :team_id="state.teamInfo.id"
          @close="state.showInviteMember = false"
          @success="getTeamById"
        />
        <ChangeLeader
          v-if="state.showChangeLeader"
          :isOwner="state.teamInfo.owner_id == state.account_id"
          :members="state.teamInfo.members"
          :team_id="state.teamInfo.id"
          @close="state.showChangeLeader = false"
          @success="getTeamById"
        />
        <CancelChangeLeader
          v-if="state.showCancelChangeLeader"
          :isOwner="state.teamInfo.owner_id == state.account_id"
          :info="state.teamInfo"
          :team_id="state.teamInfo.id"
          @close="state.showCancelChangeLeader = false"
          @success="getTeamById"
        />
        <CreateSlackChannelModal
          v-if="state.showCreateSlackChannel"
          :isSubmitting="state.creatingSlackChannel"
          @canceled="state.showCreateSlackChannel = false"
          @confirmed="createSlackChannel"
        />
        <PopupCroppie
          v-if="state.showPopupCroppie"
          :image="state.teamInfo.image"
          type="team"
          :id="null"
          @close="state.showPopupCroppie = false"
          @changeImage="changeImage"
          :viewport="{ width: 200, height: 200 }"
          :boundary="{ width: 300, height: 300 }"
        />
        <RejectTeamPopup
          v-if="state.showPopupConfirmLeave"
          :title="state.titlePopupConfirmLeave"
          :loading="state.loadingRemove"
          @close="closePopupConfirmLeave"
          @accept="leaveTeam"
        />
        <SlackAlert
          v-if="state.showSlackResultPopupAlert"
          :slackResult="state.slackResult"
          :slackCreateChannelName="state.slackCreateChannelName"
          @close="closeSlackAlert"
        />
        <Alert
          v-if="state.showPopupAlert"
          :content="state.titleAlert"
          @close="closePopupAlert"
        />
        <ConfirmTeamPopup v-if="state.confirmDelete" @close="state.confirmDelete = false" :title="state.teamInfo.name + 'を削除しますか？'" @accept="deleteTeam" :loading="state.loadingDelete"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./Detail.ts">
</script>

<style lang="scss" src="./Detail.scss" scoped>
</style>
