import { reactive, defineComponent, onMounted, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import routePath from "@/commons/RoutePath";
import TeamInfoEdit from "@/presentation/components/modals/team-info-edit/TeamInfoEdit.vue";
import InviteMember from "@/presentation/components/modals/invite-member/InviteMember.vue";
import ChangeLeader from "@/presentation/components/modals/change-leader/ChangeLeader.vue";
import CancelChangeLeader from "@/presentation/components/modals/cancel-change-leader/CancelChangeLeader.vue";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import DIContainer from "@/core/DIContainer";
import CommonService from "@/services/CommonService";
import PopupCroppie from "@/presentation/components/popup-croppie/PopupCroppie.vue";
import TeamDetailContent from "@/presentation/components/team-detail-content/TeamDetailContent.vue";
import TeamDetailPreloader from "@/presentation/components/preloader/team-detail-preloader/TeamDetailPreloader.vue";
import TransformUtil from "@/commons/TransformUtil";
import RejectTeamPopup from "@/presentation/components/modals/reject-team-popup/RejectTeamPopup.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import SlackAlert from "@/presentation/components/modals/slack-alert/SlackAlert.vue";
import RoutePath from "@/commons/RoutePath";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import ConfirmTeamPopup from "@/presentation/components/modals/reject-team-popup/RejectTeamPopup.vue";
import { useStore } from "vuex";
import CreateSlackChannelModal from "@/presentation/components/modals/create-slack-channel/CreateSlackChannel.vue";
export default defineComponent({
    name: "TeamsDetail",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        Preloaders: Preloaders,
        TeamInfoEdit: TeamInfoEdit,
        InviteMember: InviteMember,
        ChangeLeader: ChangeLeader,
        CancelChangeLeader: CancelChangeLeader,
        PopupCroppie: PopupCroppie,
        TeamDetailPreloader: TeamDetailPreloader,
        TeamDetailContent: TeamDetailContent,
        RejectTeamPopup: RejectTeamPopup,
        Alert: Alert,
        SlackAlert: SlackAlert,
        ConfirmTeamPopup: ConfirmTeamPopup,
        CreateSlackChannelModal: CreateSlackChannelModal,
    },
    props: ["breadcrumb"],
    setup: function (props) {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var state = reactive({
            teamInfo: {},
            showPopupEdit: false,
            editField: "",
            showInviteMember: false,
            showChangeLeader: false,
            showCancelChangeLeader: false,
            showCreateSlackChannel: false,
            creatingSlackChannel: false,
            preloadMain: true,
            account_id: null,
            categories: [],
            showPopupCroppie: false,
            showPopupConfirmLeave: false,
            titlePopupConfirmLeave: "",
            accountLeave: null,
            showPopupAlert: false,
            showSlackResultPopupAlert: false,
            slackResult: false,
            slackCreateChannelName: "",
            titleAlert: "",
            loadingRemove: false,
            confirmDelete: false,
            loadingDelete: false,
            isDisabledTeams: false,
            listBreadcrumb: [
                {
                    route: '/',
                    name: "ダッシュボード"
                },
                {
                    route: RoutePath.TEAMS,
                    name: "チーム一覧"
                },
                {
                    route: "",
                    name: ""
                },
            ]
        });
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        var team_id = parseInt(route.params.id);
        function openEditPopup(field) {
            state.editField = field;
            state.showPopupEdit = true;
        }
        function goback() {
            router.push(routePath.TEAMS);
        }
        function gotoUserAchievement(userId) {
            router.push("/user/profile/" + userId + "/achievement");
        }
        function gotoUserInfo(userId) {
            router.push("/user/profile/" + userId + "/info");
        }
        function proccessSkill(skills) {
            var categories = [];
            if (!skills) {
                return [];
            }
            skills = skills.sort(function (a, b) { return a.category_id - b.category_id; });
            var cache_id = null;
            skills.forEach(function (skill) {
                if (skill.category_id != cache_id) {
                    cache_id = skill.category_id;
                    categories.push({
                        category_name: skill.category_name,
                        category_id: skill.category_id,
                        skills: [skill]
                    });
                }
                else {
                    categories[categories.length - 1].skills.push(skill);
                }
            });
            return categories;
        }
        function changeImage(e) {
            state.teamInfo.image = e;
            var data = {
                team_id: state.teamInfo.id,
                image: e
            };
            return teamInteractor.updateTeam(data).then(function (result) {
            }).catch(function (error) {
            });
        }
        function updateValue(e) {
            state.teamInfo[e.field] = e.value;
        }
        function removeTeamMember(account) {
            state.accountLeave = account !== null && account !== void 0 ? account : null;
            state.titlePopupConfirmLeave = (account.user_nickname ? account.user_nickname : "") + "\u3055\u3093\u3092\u3053\u306E\u30C1\u30FC\u30E0\u304B\u3089\u8131\u9000\u3055\u305B\u307E\u3059\u304B\uFF1F";
            state.showPopupConfirmLeave = true;
        }
        function closePopupConfirmLeave() {
            state.accountLeave = null;
            state.showPopupConfirmLeave = false;
        }
        function leaveTeam() {
            var _a;
            state.loadingRemove = true;
            return teamInteractor.leaveTeam(state.teamInfo.id, (_a = state.accountLeave.user_account_id) !== null && _a !== void 0 ? _a : null).then(function (result) {
                state.loadingRemove = false;
                state.showPopupConfirmLeave = false;
                state.titleAlert = (state.accountLeave.user_nickname ? state.accountLeave.user_nickname : "") + "\u3055\u3093\u3092\u3053\u306E\u30C1\u30FC\u30E0\u304B\u3089\u8131\u9000\u3055\u305B\u307E\u3057\u305F";
                state.showPopupAlert = true;
                getTeamById();
            }).catch(function (error) {
                state.loadingRemove = false;
                if (error.message) {
                    state.showPopupConfirmLeave = false;
                    state.titleAlert = error.message;
                    state.showPopupAlert = true;
                }
            });
        }
        function closePopupAlert() {
            state.showPopupAlert = false;
            state.titleAlert = "";
        }
        function closeSlackAlert() {
            state.showSlackResultPopupAlert = false;
            state.slackResult = false;
            state.slackCreateChannelName = "";
        }
        function getTeamById() {
            state.preloadMain = true;
            return teamInteractor.getTeamById(team_id).then(function (result) {
                state.preloadMain = false;
                if (!result.data) {
                    return;
                }
                state.teamInfo = result.data;
                state.teamInfo.members.map(function (member) {
                    member.joined = true;
                    member.user_rating_ave = TransformUtil.pointRound(member.user_rating_ave);
                    member.user_rating_count = TransformUtil.formatNumber(member.user_rating_count);
                });
                if (state.teamInfo.members_inviting && state.teamInfo.members_inviting.length > 0) {
                    state.teamInfo.members_inviting.map(function (member) {
                        member.user_rating_ave = TransformUtil.pointRound(member.user_rating_ave);
                        member.user_rating_count = TransformUtil.formatNumber(member.user_rating_count);
                    });
                    state.teamInfo.members = state.teamInfo.members.concat(state.teamInfo.members_inviting);
                }
                state.categories = proccessSkill(result.data.skills ? result.data.skills : []);
            }).catch(function (error) {
                state.preloadMain = false;
            });
        }
        function deleteTeam() {
            state.loadingDelete = true;
            return teamInteractor.deleteTeam(state.teamInfo.id).then(function (result) {
                store.commit('setDeletedTeamAlert', { name: state.teamInfo.name });
                router.push(routePath.TEAMS);
            }).catch(function (error) {
                state.loadingDelete = false;
                state.confirmDelete = false;
                if (error.message) {
                    state.titleAlert = error.message;
                    state.showPopupAlert = true;
                }
            });
        }
        function createSlackChannel() {
            store.commit('setDefaultErrorAlertStatus', false);
            state.creatingSlackChannel = true;
            teamInteractor.createSlackChannel().then(function (result) {
                state.slackCreateChannelName = result.data.slack_create_channel_name;
                state.teamInfo.slack_creatable = true;
                state.showCreateSlackChannel = false;
                state.creatingSlackChannel = false;
                state.slackResult = true;
                state.showSlackResultPopupAlert = true;
                store.commit('setDefaultErrorAlertStatus', true);
            }).catch(function () {
                state.slackResult = false;
                state.showSlackResultPopupAlert = true;
                state.showCreateSlackChannel = false;
                state.creatingSlackChannel = false;
                store.commit('setDefaultErrorAlertStatus', true);
            });
        }
        var isDisabledTeams = computed(function () { return store.state.isDisabledTeams; });
        watch(function () { return store.state.isDisabledTeams; }, function (newVal) {
            state.isDisabledTeams = newVal;
        }, {
            immediate: true
        });
        onMounted(function () {
            var user = CommonService.getCookie("user");
            var user_infor = user ? JSON.parse(decodeURIComponent(user)) : null;
            state.account_id = user_infor && user_infor['account_id'] ? user_infor['account_id'] : null;
            teamInteractor.getTeamById(team_id).then(function (result) {
                state.preloadMain = false;
                if (!result.data) {
                    return;
                }
                state.teamInfo = result.data;
                state.listBreadcrumb[2] = {
                    name: state.teamInfo.name,
                    route: ""
                };
                state.teamInfo.members.map(function (member) {
                    member.joined = true;
                    member.user_rating_ave = TransformUtil.pointRound(member.user_rating_ave);
                    member.user_rating_count = TransformUtil.formatNumber(member.user_rating_count);
                });
                if (state.teamInfo.members_inviting && state.teamInfo.members_inviting.length > 0) {
                    state.teamInfo.members_inviting.map(function (member) {
                        member.user_rating_ave = TransformUtil.pointRound(member.user_rating_ave);
                        member.user_rating_count = TransformUtil.formatNumber(member.user_rating_count);
                    });
                    state.teamInfo.members = state.teamInfo.members.concat(state.teamInfo.members_inviting);
                }
                state.categories = proccessSkill(result.data.skills ? result.data.skills : []);
            }).catch(function (error) {
                state.preloadMain = false;
                router.push(routePath.PAGE_404);
            });
            store.dispatch("checkActiveTeamInput");
        });
        return {
            state: state,
            routePath: routePath,
            openEditPopup: openEditPopup,
            goback: goback,
            gotoUserAchievement: gotoUserAchievement,
            gotoUserInfo: gotoUserInfo,
            changeImage: changeImage,
            updateValue: updateValue,
            leaveTeam: leaveTeam,
            getTeamById: getTeamById,
            removeTeamMember: removeTeamMember,
            closePopupConfirmLeave: closePopupConfirmLeave,
            closePopupAlert: closePopupAlert,
            closeSlackAlert: closeSlackAlert,
            deleteTeam: deleteTeam,
            createSlackChannel: createSlackChannel,
            isDisabledTeams: isDisabledTeams
        };
    }
});
